
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

import location from '@/mixins/location.js'

export default {
  mixins: [
    location,
    clickaway
  ],

  data: () => ({
    columns: 3,
    timeout: null,
    timer: 1000,
    form: {
      cities: {
        items: [],
        // major: [],
        loading: false
      },
      city: {
        value: {
          id: '',
          name: ''
        },
        searching: false,
        query: '',
        isActiveDropdown: false,
        name: '',
        error: ''
      }
    }
  }),

  computed: {
    ...mapGetters({
      cities: 'cities',
      location: 'location'
    }),

    chunkedCities () {
      const res = []
      const size = Math.ceil(this.cities.length / this.columns)

      for (let i = 0; i < this.cities.length; i += size) {
        const chunk = this.cities.slice(i, i + size)
        res.push(chunk)
      }

      return res
    }
  },

  methods: {
    hideCityDropDown () {
      this.form.city.isActiveDropdown = false
    },

    toggleCitySelect () {
      this.form.city.isActiveDropdown = !this.form.city.isActiveDropdown

      this.$nextTick(() => {
        this.$refs.city.focus()
      })
    },

    search () {
      this.form.cities.loading = true
      if (this.form.city.query && this.form.city.query.length < 3) {
        return
      } else if (this.form.city.query === '') {
        this.form.cities.items = []
        return
      }

      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.searchCity()
      }, this.timer)
    },

    searchCity () {
      this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/cities?q=${this.form.city.query}`)
        .then((res) => {
          this.form.cities.items = res.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.form.cities.loading = false
        })
    }
  }
}
